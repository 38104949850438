import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';


import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';



import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const fiveDaysBefore = new Date(currentDate.getTime() - 4 * 24 * 60 * 60 * 1000);
  const oneDayAfter = new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000);
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  const formatDateString = (date) => {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };



  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
        document.body.style.overflow = "scroll"
    };
}, []);

useEffect(() => {
  setFetchAttempted(true); // Set to true as fetch begins

  fetch('/api/location')
    .then(response => response.json())
    .then(data => {
      const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));

      if (decodedCity === "unknown") {
        // If city is "unknown", fetch from the external API
        fetch('https://freeipapi.com/api/json/')
          .then(response => response.json())
          .then(externalData => {
            const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
            setCountry(externalData.countryCode);
            setCity(externalDecodedCity);
            console.log(externalData); // Log the external API data
          })
          .catch(error => {
            console.error('Fetch error on external API:', error);
          });
      } else {
        // Use the local API data
        setCountry(data.country);
        setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
        setCity(decodedCity);
        console.log(data); // Log the local API data
      }
    })
    .catch(error => {
      console.error('Fetch error on local API:', error);
      // Optionally handle the error by fetching from external API or other means
    });

}, []);



  useEffect(() => {
    // Update the countdown timer every second
    const timer = setTimeout(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000);

    // Clear the timer when component unmounts or timeLeft changes
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Function to format the remaining time as "0h 0m 0s"
  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };


  return (
    <div className="container">


    <a class="header-logo" href="https://onlyfans.com/theonlychriiiis/c9">
     <div class="onlyfans"></div>
    </a>
      <img 
        src="https://i.ibb.co/NSGmndZ/IMG-3423-copia-modified-min.png" 
        alt="Header Image"
        className="header-image"
      />

<div className='textContainer'>
        <section>
        <div className='username'><strong>Chris</strong></div>
            <div className='useraccountname'><strong>@theonlychriiiis</strong></div>

            <div id="user-status" class="user-status">
            <div class="status"></div>
            <div id="available"><strong>Available now</strong></div>
            <div class="separator"></div>
            <div class="clock"></div>  
            <div class="respond-time"><strong>Responds in 2 minutes</strong></div>
          </div>

        </section>
      </div>

      <div class="textContainer">
      <div class="location"></div>
        <span id="location-name">{city ? <strong>{city} {region}</strong>: '<3'}</span>
      </div>

      <div class="textContainer2">
      <div class="time-in-current-location">
        <strong>I’m staying in {city || 'your city'} from {formatDateString(fiveDaysBefore)} to {formatDateString(oneDayAfter)} 🥰<br/>
        Matches only: send me a ❤️ in my DMs</strong>
      </div>
      </div>


      <div class="textContainer3">
      <a href="https://onlyfans.com/theonlychriiiis/c9" id="customButton">
      <div class="onlyfans"></div>
       My NSFW Onlyfans page 🔞
      </a>
      </div>



      <div class="countdown-block">
      <span class="discount"><strong>50% OFF</strong></span>
      <span class="timer-end"><strong>ends in</strong> </span>
      <span id="timer"><strong>{formatTimeLeft(timeLeft)}</strong></span>
    </div>
    

    
      <Analytics/>
    </div>


  );
}

export default App;
